export default {
  v: '5.9.4',
  fr: 25,
  ip: 0,
  op: 101,
  w: 200,
  h: 200,
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      fr: 25,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 3,
          sr: 1,
          ks: {
            o: { a: 0, k: 0, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 0,
                  s: [540, 818, 0],
                  to: [0, -35.333, 0],
                  ti: [0, 35.333, 0]
                },
                {
                  i: { x: 0.201, y: 0.201 },
                  o: { x: 0.493, y: 0.493 },
                  t: 28,
                  s: [540, 606, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.559, y: 1 },
                  o: { x: 0.238, y: 0 },
                  t: 42,
                  s: [540, 606, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.559, y: 0.559 },
                  o: { x: 0.167, y: 0.167 },
                  t: 58,
                  s: [540, 452, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                {
                  i: { x: 0.559, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 82,
                  s: [540, 452, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 96, s: [540, 347, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ip: 0,
          op: 52234,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [540.537, 544.993, 0], ix: 2, l: 2 },
            a: { a: 0, k: [14.489, 12.155, 0], ix: 1, l: 2 },
            s: { a: 0, k: [503, 503, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.345098048449, 0.788235366344, 0.478431403637, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [106.978, 106.978], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.221155069389, 0.670000023935, 0.356205719593, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.489, 12.155], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 52234,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          parent: 1,
          tt: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.326, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 42,
                  s: [46, 567, 0],
                  to: [0, -15.333, 0],
                  ti: [0, 15.333, 0]
                },
                { t: 52, s: [46, 475, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 42,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [420.507, -586.507],
                              [478.507, 478.507],
                              [-478.507, 478.507],
                              [-442.507, -586.507],
                              [-40.507, -472.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 45,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [420.507, -586.507],
                              [478.507, 478.507],
                              [-478.507, 478.507],
                              [-442.507, -586.507],
                              [-40.507, -472.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 46,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [420.507, -586.507],
                              [478.507, 478.507],
                              [-478.507, 478.507],
                              [-448.507, -484.507],
                              [-40.507, -472.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 47,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [404.507, -654.507],
                              [478.507, 478.507],
                              [-478.507, 478.507],
                              [-448.507, -422.507],
                              [-40.507, -472.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 48,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [404.507, -654.507],
                              [478.507, 478.507],
                              [-478.507, 478.507],
                              [-448.507, -422.507],
                              [-40.507, -472.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 49,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [404.507, -654.507],
                              [478.507, 478.507],
                              [-478.507, 478.507],
                              [-454.507, -458.507],
                              [-40.507, -472.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 50,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [400.507, -662.507],
                              [478.507, 478.507],
                              [-478.507, 478.507],
                              [-454.507, -460.507],
                              [-40.507, -472.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 51,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [400.507, -662.507],
                              [478.507, 478.507],
                              [-478.507, 478.507],
                              [-456.507, -480.507],
                              [-40.507, -472.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 52,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [400.507, -662.507],
                              [478.507, 478.507],
                              [-478.507, 478.507],
                              [-456.507, -490.507],
                              [-40.507, -472.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 63,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [410.507, -614.507],
                              [478.507, 478.507],
                              [-478.507, 478.507],
                              [-456.507, -490.507],
                              [-40.507, -472.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 66,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [426.507, -546.507],
                              [478.507, 478.507],
                              [-478.507, 478.507],
                              [-456.507, -490.507],
                              [-40.507, -472.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 67,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [421.84, -553.174],
                              [478.507, 478.507],
                              [-478.507, 478.507],
                              [-456.507, -490.507],
                              [-40.507, -472.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 68,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [426.174, -541.84],
                              [478.507, 478.507],
                              [-478.507, 478.507],
                              [-456.507, -490.507],
                              [-40.507, -472.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 69,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [410.507, -532.507],
                              [478.507, 478.507],
                              [-478.507, 478.507],
                              [-456.507, -490.507],
                              [-40.507, -472.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 70,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [414.507, -522.507],
                              [478.507, 478.507],
                              [-478.507, 478.507],
                              [-456.507, -490.507],
                              [-40.507, -472.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 71,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [410.507, -518.507],
                              [478.507, 478.507],
                              [-478.507, 478.507],
                              [-456.507, -490.507],
                              [-40.507, -472.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 72,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [398.507, -514.507],
                              [466.507, 482.507],
                              [-490.507, 482.507],
                              [-468.507, -486.507],
                              [-52.507, -468.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 73,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [398.507, -514.507],
                              [466.507, 482.507],
                              [-490.507, 482.507],
                              [-468.507, -486.507],
                              [-52.507, -468.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 74,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [398.507, -504.507],
                              [466.507, 482.507],
                              [-490.507, 482.507],
                              [-468.507, -486.507],
                              [-52.507, -468.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 75,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.833, -4.021]
                            ],
                            v: [
                              [402.507, -496.507],
                              [466.507, 482.507],
                              [-490.507, 482.507],
                              [-468.507, -486.507],
                              [-52.507, -468.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 76,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.834, -4.021]
                            ],
                            v: [
                              [406.507, -536.507],
                              [466.507, 482.507],
                              [-490.507, 482.507],
                              [-468.507, -486.507],
                              [-14.507, -450.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 77,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.834, -4.021]
                            ],
                            v: [
                              [398.507, -536.507],
                              [466.507, 482.507],
                              [-490.507, 482.507],
                              [-464.507, -554.507],
                              [-14.507, -450.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 78,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.834, -4.021]
                            ],
                            v: [
                              [396.507, -548.507],
                              [466.507, 482.507],
                              [-490.507, 482.507],
                              [-468.507, -526.507],
                              [-14.507, -450.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 79,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.834, -4.021]
                            ],
                            v: [
                              [410.507, -542.507],
                              [466.507, 482.507],
                              [-490.507, 482.507],
                              [-468.507, -486.507],
                              [-14.507, -434.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 80,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.834, -4.021]
                            ],
                            v: [
                              [396.507, -578.507],
                              [466.507, 482.507],
                              [-490.507, 482.507],
                              [-468.507, -486.507],
                              [-14.507, -434.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 81,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.834, -4.021]
                            ],
                            v: [
                              [396.507, -578.507],
                              [466.507, 482.507],
                              [-490.507, 482.507],
                              [-456.507, -522.507],
                              [-24.507, -426.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 82,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.834, -4.021]
                            ],
                            v: [
                              [396.507, -578.507],
                              [466.507, 482.507],
                              [-490.507, 482.507],
                              [-452.507, -524.507],
                              [-24.507, -426.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 83,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.834, -4.021]
                            ],
                            v: [
                              [396.507, -578.507],
                              [466.507, 482.507],
                              [-490.507, 482.507],
                              [-446.507, -524.507],
                              [-24.507, -426.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 84,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.834, -4.021]
                            ],
                            v: [
                              [396.507, -578.507],
                              [466.507, 482.507],
                              [-490.507, 482.507],
                              [-430.507, -508.507],
                              [-24.507, -426.507]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        t: 85,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-140.769, 3.476]
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [162.834, -4.021]
                            ],
                            v: [
                              [396.507, -578.507],
                              [466.507, 482.507],
                              [-490.507, 482.507],
                              [-424.507, -500.507],
                              [-24.507, -426.507]
                            ],
                            c: true
                          }
                        ]
                      }
                    ],
                    ix: 2
                  },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.345098039216, 0.788235353956, 0.478431402468, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [34.507, 110.507], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 52234,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [540.537, 544.993, 0], ix: 2, l: 2 },
            a: { a: 0, k: [14.489, 12.155, 0], ix: 1, l: 2 },
            s: { a: 0, k: [503, 503, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.345098048449, 0.788235366344, 0.478431403637, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [106.978, 106.978], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.221155069389, 0.670000023935, 0.356205719593, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.489, 12.155], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 76,
          op: 114,
          st: 76,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 5,
          ty: 0,
          parent: 1,
          tt: 1,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
            a: { a: 0, k: [540, 540, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.345098048449, 0.78823530674, 0.478431373835, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          w: 1080,
          h: 1080,
          ip: 111,
          op: 114,
          st: 111,
          bm: 0
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [540.537, 544.993, 0], ix: 2, l: 2 },
            a: { a: 0, k: [14.489, 12.155, 0], ix: 1, l: 2 },
            s: { a: 0, k: [503, 503, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.345098048449, 0.788235366344, 0.478431403637, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [106.978, 106.978], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.221155069389, 0.670000023935, 0.356205719593, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.489, 12.155], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 76,
          op: 111,
          st: 76,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 7,
          ty: 0,
          parent: 1,
          tt: 1,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
            a: { a: 0, k: [540, 540, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.345098048449, 0.78823530674, 0.478431373835, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          w: 1080,
          h: 1080,
          ip: 76,
          op: 111,
          st: 73,
          bm: 0
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [540.537, 544.993, 0], ix: 2, l: 2 },
            a: { a: 0, k: [14.489, 12.155, 0], ix: 1, l: 2 },
            s: { a: 0, k: [503, 503, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.345098048449, 0.788235366344, 0.478431403637, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [106.978, 106.978], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.221155069389, 0.670000023935, 0.356205719593, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.489, 12.155], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 76,
          op: 114,
          st: 76,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 9,
          ty: 0,
          parent: 1,
          tt: 1,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
            a: { a: 0, k: [540, 540, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.29771143198, 0.680000007153, 0.412736326456, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          w: 1080,
          h: 1080,
          ip: 76,
          op: 114,
          st: 76,
          bm: 0
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [540.537, 544.993, 0], ix: 2, l: 2 },
            a: { a: 0, k: [14.489, 12.155, 0], ix: 1, l: 2 },
            s: { a: 0, k: [503, 503, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.345098048449, 0.788235366344, 0.478431403637, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [106.978, 106.978], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.221155069389, 0.670000023935, 0.356205719593, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.489, 12.155], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 38,
          op: 76,
          st: 38,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 11,
          ty: 0,
          parent: 1,
          tt: 1,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
            a: { a: 0, k: [540, 540, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.345098048449, 0.78823530674, 0.478431373835, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          w: 1080,
          h: 1080,
          ip: 73,
          op: 76,
          st: 73,
          bm: 0
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [540.537, 544.993, 0], ix: 2, l: 2 },
            a: { a: 0, k: [14.489, 12.155, 0], ix: 1, l: 2 },
            s: { a: 0, k: [503, 503, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.345098048449, 0.788235366344, 0.478431403637, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [106.978, 106.978], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.221155069389, 0.670000023935, 0.356205719593, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.489, 12.155], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 38,
          op: 73,
          st: 38,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 13,
          ty: 0,
          parent: 1,
          tt: 1,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
            a: { a: 0, k: [540, 540, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.345098048449, 0.78823530674, 0.478431373835, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          w: 1080,
          h: 1080,
          ip: 38,
          op: 73,
          st: 35,
          bm: 0
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [540.537, 544.993, 0], ix: 2, l: 2 },
            a: { a: 0, k: [14.489, 12.155, 0], ix: 1, l: 2 },
            s: { a: 0, k: [503, 503, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.345098048449, 0.788235366344, 0.478431403637, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [106.978, 106.978], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.221155069389, 0.670000023935, 0.356205719593, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.489, 12.155], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 38,
          op: 76,
          st: 38,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 15,
          ty: 0,
          parent: 1,
          tt: 1,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
            a: { a: 0, k: [540, 540, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.29771143198, 0.680000007153, 0.412736326456, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          w: 1080,
          h: 1080,
          ip: 38,
          op: 76,
          st: 38,
          bm: 0
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [540.537, 544.993, 0], ix: 2, l: 2 },
            a: { a: 0, k: [14.489, 12.155, 0], ix: 1, l: 2 },
            s: { a: 0, k: [503, 503, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.345098048449, 0.788235366344, 0.478431403637, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [106.978, 106.978], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.221155069389, 0.670000023935, 0.356205719593, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.489, 12.155], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 38,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 17,
          ty: 0,
          parent: 1,
          tt: 1,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
            a: { a: 0, k: [540, 540, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.345098048449, 0.78823530674, 0.478431373835, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          w: 1080,
          h: 1080,
          ip: 35,
          op: 38,
          st: 35,
          bm: 0
        },
        {
          ddd: 0,
          ind: 18,
          ty: 4,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [540.537, 544.993, 0], ix: 2, l: 2 },
            a: { a: 0, k: [14.489, 12.155, 0], ix: 1, l: 2 },
            s: { a: 0, k: [503, 503, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.345098048449, 0.788235366344, 0.478431403637, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [106.978, 106.978], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.221155069389, 0.670000023935, 0.356205719593, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.489, 12.155], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 35,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 19,
          ty: 0,
          parent: 1,
          tt: 1,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
            a: { a: 0, k: [540, 540, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.345098048449, 0.78823530674, 0.478431373835, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          w: 1080,
          h: 1080,
          ip: 0,
          op: 35,
          st: -3,
          bm: 0
        },
        {
          ddd: 0,
          ind: 20,
          ty: 4,
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [540.537, 544.993, 0], ix: 2, l: 2 },
            a: { a: 0, k: [14.489, 12.155, 0], ix: 1, l: 2 },
            s: { a: 0, k: [503, 503, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.345098048449, 0.788235366344, 0.478431403637, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [106.978, 106.978], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.221155069389, 0.670000023935, 0.356205719593, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [14.489, 12.155], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 38,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 21,
          ty: 0,
          parent: 1,
          tt: 1,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
            a: { a: 0, k: [540, 540, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              np: 9,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 }
                },
                {
                  ty: 7,
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 }
                },
                {
                  ty: 2,
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.29771143198, 0.680000007153, 0.412736326456, 1],
                    ix: 3
                  }
                },
                {
                  ty: 7,
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 }
                },
                {
                  ty: 0,
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 }
                },
                {
                  ty: 0,
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 }
                },
                {
                  ty: 0,
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 }
                }
              ]
            }
          ],
          w: 1080,
          h: 1080,
          ip: 0,
          op: 38,
          st: 0,
          bm: 0
        }
      ]
    },
    {
      id: 'comp_1',
      fr: 25,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [540, 540, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [255.5, 39.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [43.085, 1.446],
                              [33.932, 2.324],
                              [25.654, 3.632]
                            ],
                            o: [
                              [-155.344, -24.016],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-28.955, -0.972],
                              [-30.616, -2.097],
                              [-113, -16]
                            ],
                            v: [
                              [-323.5, -47.5],
                              [-205.745, 305.749],
                              [-6, 384],
                              [286.065, 302.276],
                              [304, 51],
                              [229.784, 49.294],
                              [132.542, 44.49],
                              [46, 36]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 1,
                        s: [
                          {
                            i: [
                              [255.5, 39.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [52.164, 1.014],
                              [38.771, 2.093],
                              [25.653, 3.632]
                            ],
                            o: [
                              [-155.344, -24.016],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-35.057, -0.681],
                              [-34.982, -1.889],
                              [-113, -16]
                            ],
                            v: [
                              [-326.5, -39.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [300, 51],
                              [208.614, 50.111],
                              [93.883, 46.141],
                              [0, 38]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 2,
                        s: [
                          {
                            i: [
                              [250.5, 49.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [51.732, -1.58],
                              [38.541, 0.711],
                              [25.653, 3.632]
                            ],
                            o: [
                              [-154.208, -30.472],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-34.766, 1.062],
                              [-34.774, -0.641],
                              [-113, -16]
                            ],
                            v: [
                              [-329.5, -31.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [298, 49],
                              [207.432, 53.017],
                              [93.534, 54.043],
                              [0, 48]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 3,
                        s: [
                          {
                            i: [
                              [243.5, 48.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [52.253, -5.066],
                              [39.116, -2.245],
                              [26.347, 1.065]
                            ],
                            o: [
                              [-154.161, -30.706],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-35.117, 3.405],
                              [-35.293, 2.026],
                              [-114.035, -4.607]
                            ],
                            v: [
                              [-332.5, -10.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [297, 43],
                              [205.642, 52.882],
                              [90.354, 62.049],
                              [-5, 64]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 4,
                        s: [
                          {
                            i: [
                              [238.5, 52.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [54.417, -7.7],
                              [40.267, -3.614],
                              [26.344, 1.145]
                            ],
                            o: [
                              [-153.514, -33.792],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-36.571, 5.175],
                              [-36.332, 3.261],
                              [-115, -5]
                            ],
                            v: [
                              [-332.5, -1.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [295, 38],
                              [199.55, 52.886],
                              [80.096, 67.083],
                              [-17, 71]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 5,
                        s: [
                          {
                            i: [
                              [230.5, 55.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [54.96, -9.869],
                              [41.582, -4.577],
                              [28.743, 1.597]
                            ],
                            o: [
                              [-152.822, -36.797],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-36.936, 6.632],
                              [-37.518, 4.13],
                              [-92.905, -5.161]
                            ],
                            v: [
                              [-332.5, 6.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [288, 35],
                              [192.202, 54.115],
                              [70.37, 72.239],
                              [-32, 77]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 6,
                        s: [
                          {
                            i: [
                              [220.5, 72.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [54.367, -10.165],
                              [36.912, -5.489],
                              [27.756, 0.448]
                            ],
                            o: [
                              [-149.325, -49.098],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-36.537, 6.832],
                              [-33.305, 4.952],
                              [-62, -1]
                            ],
                            v: [
                              [-334.5, 9.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [265, 34],
                              [155.044, 52.351],
                              [42.98, 72.222],
                              [-50, 80]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 7,
                        s: [
                          {
                            i: [
                              [220.5, 72.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [77.449, -14.892],
                              [29.022, -6.668],
                              [27.189, 0.604]
                            ],
                            o: [
                              [-149.325, -49.098],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-30.045, 5.777],
                              [-26.186, 6.016],
                              [-61.993, -1.378]
                            ],
                            v: [
                              [-338.5, 17.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [265, 34],
                              [113.635, 55.101],
                              [25.626, 76.14],
                              [-54, 86]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 8,
                        s: [
                          {
                            i: [
                              [220.5, 72.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [77.449, -14.892],
                              [31.504, -9.314],
                              [29.882, -1.423]
                            ],
                            o: [
                              [-149.325, -49.098],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-30.045, 5.777],
                              [-28.425, 8.404],
                              [-61.938, 2.949]
                            ],
                            v: [
                              [-339.5, 34.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [265, 25],
                              [111.635, 47.101],
                              [19.397, 72.921],
                              [-68, 90]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 9,
                        s: [
                          {
                            i: [
                              [212.5, 62.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [77.449, -14.892],
                              [32.142, -9.835],
                              [33.676, -5.692]
                            ],
                            o: [
                              [-150.802, -44.354],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-30.045, 5.777],
                              [-29.001, 8.874],
                              [-61.141, 10.334]
                            ],
                            v: [
                              [-341.5, 38.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [265, 17],
                              [110.635, 41.101],
                              [18.312, 66.613],
                              [-75, 90]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 10,
                        s: [
                          {
                            i: [
                              [212.5, 62.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [76.967, -17.21],
                              [27.702, -8.65],
                              [33.396, -7.156]
                            ],
                            o: [
                              [-150.802, -44.354],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-35.029, 7.832],
                              [-24.994, 7.804],
                              [-56, 12]
                            ],
                            v: [
                              [-343.5, 45.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [265, 8],
                              [110.635, 34.101],
                              [21.177, 59.248],
                              [-63, 82]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 11,
                        s: [
                          {
                            i: [
                              [212.5, 62.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [76.967, -17.21],
                              [27.702, -9.174],
                              [33.396, -7.156]
                            ],
                            o: [
                              [-150.802, -44.354],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-35.029, 7.832],
                              [-24.994, 8.277],
                              [-56, 12]
                            ],
                            v: [
                              [-346.5, 48.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [265, 2],
                              [108.635, 27.101],
                              [19.177, 53.325],
                              [-65, 77]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 12,
                        s: [
                          {
                            i: [
                              [222.5, 55.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [76.967, -17.21],
                              [27.701, -9.174],
                              [33.396, -7.156]
                            ],
                            o: [
                              [-152.517, -38.043],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-35.029, 7.832],
                              [-24.994, 8.277],
                              [-56, 12]
                            ],
                            v: [
                              [-349.5, 53.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [266, -2],
                              [107.635, 20.101],
                              [18.177, 46.325],
                              [-66, 70]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 13,
                        s: [
                          {
                            i: [
                              [207.5, 34.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [76.967, -17.21],
                              [27.887, -8.462],
                              [32.643, -10.044]
                            ],
                            o: [
                              [-155.061, -25.781],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-35.029, 7.832],
                              [-25.161, 7.635],
                              [-52, 16]
                            ],
                            v: [
                              [-348.5, 61.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [266, -19],
                              [107.635, 3.101],
                              [17.554, 26.931],
                              [-66, 53]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 14,
                        s: [
                          {
                            i: [
                              [200.5, 27.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [76.967, -17.21],
                              [29.198, -7.676],
                              [32.643, -10.044]
                            ],
                            o: [
                              [-155.732, -21.36],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-35.029, 7.832],
                              [-26.344, 6.926],
                              [-52, 16]
                            ],
                            v: [
                              [-348.5, 58.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [266, -22],
                              [107.635, -6.899],
                              [14.861, 15.315],
                              [-71, 40]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 15,
                        s: [
                          {
                            i: [
                              [199.5, 15.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [76.967, -17.21],
                              [29.46, -7.676],
                              [32.643, -10.044]
                            ],
                            o: [
                              [-156.717, -12.176],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-35.029, 7.832],
                              [-26.581, 6.926],
                              [-52, 16]
                            ],
                            v: [
                              [-348.5, 59.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [266, -28],
                              [109.635, -15.899],
                              [16.323, 6.315],
                              [-70, 31]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 16,
                        s: [
                          {
                            i: [
                              [199.5, 15.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [80.365, -13.101],
                              [29.875, -6.928],
                              [32.643, -10.044]
                            ],
                            o: [
                              [-156.717, -12.176],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-35.427, 5.775],
                              [-26.955, 6.251],
                              [-52, 16]
                            ],
                            v: [
                              [-350.5, 52.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [266, -36],
                              [110.635, -25.899],
                              [15.978, -7.766],
                              [-71, 16]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 17,
                        s: [
                          {
                            i: [
                              [196.5, -5.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [80.365, -13.101],
                              [30.293, -5.993],
                              [33.074, -8.519]
                            ],
                            o: [
                              [-157.128, 4.398],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-35.427, 5.775],
                              [-27.333, 5.408],
                              [-66, 17]
                            ],
                            v: [
                              [-347.5, 55.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [266, -36],
                              [110.635, -31.899],
                              [15.258, -15.194],
                              [-73, 5]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 18,
                        s: [
                          {
                            i: [
                              [196.5, -5.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [82.365, -5.101],
                              [30.708, -3.821],
                              [33.074, -8.519]
                            ],
                            o: [
                              [-157.128, 4.398],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-35.826, 2.219],
                              [-27.707, 3.447],
                              [-66, 17]
                            ],
                            v: [
                              [-351.5, 35.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [266, -47],
                              [111.635, -44.899],
                              [14.911, -37.056],
                              [-74, -20]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 19,
                        s: [
                          {
                            i: [
                              [157.5, -36.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [82.365, -5.101],
                              [30.446, -2.772],
                              [33.074, -8.519]
                            ],
                            o: [
                              [-153.132, 35.488],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-35.826, 2.219],
                              [-27.471, 2.501],
                              [-66, 17]
                            ],
                            v: [
                              [-333.5, 38.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [266, -47],
                              [111.635, -51.899],
                              [15.45, -46.21],
                              [-73, -31]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 20,
                        s: [
                          {
                            i: [
                              [157.5, -36.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [85.365, -1.101],
                              [30.659, -2.639],
                              [33.202, -5.692]
                            ],
                            o: [
                              [-153.132, 35.488],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-35.891, 0.463],
                              [-27.663, 2.381],
                              [-67.174, 11.516]
                            ],
                            v: [
                              [-333.5, 27.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [266, -47],
                              [110.635, -57.899],
                              [13.973, -53.744],
                              [-75, -42]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 21,
                        s: [
                          {
                            i: [
                              [157.5, -36.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [85.037, 7.559],
                              [30.083, -1.022],
                              [33.202, -5.692]
                            ],
                            o: [
                              [-153.132, 35.488],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-36.081, -3.207],
                              [-27.143, 0.922],
                              [-67.174, 11.516]
                            ],
                            v: [
                              [-335.5, 16.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [266, -56],
                              [110.635, -57.899],
                              [14.922, -61.606],
                              [-73, -52]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 22,
                        s: [
                          {
                            i: [
                              [157.5, -43.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [85.037, 7.559],
                              [32.967, 0.027],
                              [33.202, -5.692]
                            ],
                            o: [
                              [-151.517, 41.848],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-36.081, -3.207],
                              [-29.745, -0.024],
                              [-67.174, 11.516]
                            ],
                            v: [
                              [-338.5, 7.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [266, -56],
                              [118.635, -62.899],
                              [16.999, -68.76],
                              [-76, -61]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 23,
                        s: [
                          {
                            i: [
                              [157.5, -43.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [84.337, 13.254],
                              [36.144, 3.704],
                              [35.099, -3.32]
                            ],
                            o: [
                              [-151.517, 41.848],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-37.132, -5.836],
                              [-32.611, -3.342],
                              [-67.851, 6.418]
                            ],
                            v: [
                              [-338.5, -14.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [266, -49],
                              [130.635, -60.899],
                              [21.784, -76.799],
                              [-79, -78]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 24,
                        s: [
                          {
                            i: [
                              [157.5, -43.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [84.337, 13.254],
                              [35.588, 4.547],
                              [38.419, -1.423]
                            ],
                            o: [
                              [-151.517, 41.848],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-37.132, -5.836],
                              [-32.11, -4.103],
                              [-68.107, 2.522]
                            ],
                            v: [
                              [-338.5, -24.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [266, -49],
                              [130.635, -60.899],
                              [23.999, -77.919],
                              [-80, -83]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 25,
                        s: [
                          {
                            i: [
                              [157.5, -43.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [84.337, 13.254],
                              [36.636, 5.596],
                              [38.419, -1.423]
                            ],
                            o: [
                              [-151.517, 41.848],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-37.132, -5.836],
                              [-33.056, -5.049],
                              [-68.107, 2.522]
                            ],
                            v: [
                              [-338.5, -33.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [268, -43],
                              [130.635, -60.899],
                              [21.845, -80.073],
                              [-84, -87]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 26,
                        s: [
                          {
                            i: [
                              [157.5, -43.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [84.337, 13.254],
                              [38.209, 7.955],
                              [38.419, -1.423]
                            ],
                            o: [
                              [-151.517, 41.848],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-37.132, -5.836],
                              [-34.475, -7.178],
                              [-68.107, 2.522]
                            ],
                            v: [
                              [-339.5, -40.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [270, -34],
                              [136.635, -56.899],
                              [24.614, -80.919],
                              [-84, -92]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 27,
                        s: [
                          {
                            i: [
                              [157.5, -43.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [84.337, 13.254],
                              [42.227, 9.585],
                              [41.265, 0.474]
                            ],
                            o: [
                              [-151.517, 41.848],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-37.132, -5.836],
                              [-38.1, -8.648],
                              [-68.15, -0.783]
                            ],
                            v: [
                              [-341.5, -48.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [270, -30],
                              [154.635, -50.899],
                              [35.28, -77.654],
                              [-84, -94]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 28,
                        s: [
                          {
                            i: [
                              [157.5, -43.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [84.337, 13.254],
                              [42.694, 12.263],
                              [39.368, 2.372]
                            ],
                            o: [
                              [-151.517, 41.848],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-37.132, -5.836],
                              [-38.521, -11.065],
                              [-68.031, -4.098]
                            ],
                            v: [
                              [-341.5, -56.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [270, -16],
                              [154.635, -38.899],
                              [33.707, -70.543],
                              [-84, -94]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 29,
                        s: [
                          {
                            i: [
                              [157.5, -43.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [84.337, 13.254],
                              [43.481, 13.312],
                              [39.368, 2.372]
                            ],
                            o: [
                              [-151.517, 41.848],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-37.132, -5.836],
                              [-39.231, -12.011],
                              [-68.031, -4.098]
                            ],
                            v: [
                              [-341.5, -56.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [272, -8],
                              [154.635, -31.899],
                              [32.092, -65.697],
                              [-87, -91]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 30,
                        s: [
                          {
                            i: [
                              [157.5, -43.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [84.337, 13.254],
                              [44.618, 12.639],
                              [37.945, 6.166]
                            ],
                            o: [
                              [-151.517, 41.848],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-37.132, -5.836],
                              [-40.257, -11.404],
                              [-67.272, -10.932]
                            ],
                            v: [
                              [-341.5, -56.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [272, -1],
                              [158.635, -26.899],
                              [33.296, -58.09],
                              [-86, -87]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 31,
                        s: [
                          {
                            i: [
                              [157.5, -43.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [83.365, 18.899],
                              [40.029, 11.699],
                              [37.945, 6.166]
                            ],
                            o: [
                              [-151.517, 41.848],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-36.657, -8.31],
                              [-36.117, -10.556],
                              [-67.272, -10.932]
                            ],
                            v: [
                              [-341.5, -56.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [272, 9],
                              [140.635, -20.899],
                              [25.31, -53.222],
                              [-86, -80]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 32,
                        s: [
                          {
                            i: [
                              [157.5, -43.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [83.365, 18.899],
                              [40.291, 11.961],
                              [37.945, 6.166]
                            ],
                            o: [
                              [-151.517, 41.848],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-36.657, -8.31],
                              [-36.353, -10.792],
                              [-67.272, -10.932]
                            ],
                            v: [
                              [-341.5, -56.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [272, 11],
                              [138.635, -11.899],
                              [22.771, -44.76],
                              [-89, -72]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 33,
                        s: [
                          {
                            i: [
                              [157.5, -43.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [83.365, 18.899],
                              [40.844, 11.377],
                              [38.894, 8.538]
                            ],
                            o: [
                              [-151.517, 41.848],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-36.657, -8.31],
                              [-36.852, -10.265],
                              [-66.569, -14.613]
                            ],
                            v: [
                              [-348.5, -48.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [268, 27],
                              [136.635, 5.101],
                              [19.942, -25.794],
                              [-94, -55]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 34,
                        s: [
                          {
                            i: [
                              [146.5, -33.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [83.365, 18.899],
                              [43.179, 12.295],
                              [39.969, 9.689]
                            ],
                            o: [
                              [-153.235, 35.04],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-39.105, -8.865],
                              [-33.818, -9.629],
                              [-66, -16]
                            ],
                            v: [
                              [-363.5, -44.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [268, 34],
                              [132.635, 12.101],
                              [10.878, -14.573],
                              [-99, -50]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 35,
                        s: [
                          {
                            i: [
                              [146.5, -33.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [83.365, 18.899],
                              [43.179, 12.295],
                              [39.969, 9.689]
                            ],
                            o: [
                              [-153.235, 35.04],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-39.105, -8.865],
                              [-33.818, -9.629],
                              [-66, -16]
                            ],
                            v: [
                              [-363.5, -44.5],
                              [-208.745, 313.749],
                              [-9, 392],
                              [283.065, 310.276],
                              [268, 34],
                              [134.635, 23.101],
                              [8.878, -4.573],
                              [-101, -40]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 36,
                        s: [
                          {
                            i: [
                              [199.5, 8],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [63.225, 10.173],
                              [38.556, 7.309],
                              [46.5, 13]
                            ],
                            o: [
                              [-154.289, 5.512],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-34.03, -4.919],
                              [-32.217, -5.863],
                              [-96.5, -30]
                            ],
                            v: [
                              [-343.5, -58],
                              [-207.245, 309.749],
                              [-7.5, 388],
                              [284.565, 306.276],
                              [286, 42.5],
                              [182.209, 36.197],
                              [70.71, 19.959],
                              [-33.5, -5]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        t: 37,
                        s: [
                          {
                            i: [
                              [255.5, 39.5],
                              [-137.668, -137.931],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [43.085, 1.446],
                              [33.932, 2.324],
                              [25.654, 3.632]
                            ],
                            o: [
                              [-155.344, -24.016],
                              [85.276, 85.439],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-28.955, -0.972],
                              [-30.616, -2.097],
                              [-113, -16]
                            ],
                            v: [
                              [-323.5, -47.5],
                              [-205.745, 305.749],
                              [-6, 384],
                              [286.065, 302.276],
                              [304, 51],
                              [229.784, 49.294],
                              [132.542, 44.49],
                              [46, 36]
                            ],
                            c: true
                          }
                        ]
                      }
                    ],
                    ix: 2
                  },
                  hd: false
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.345098039216, 0.788235353956, 0.478431402468, 1],
                    ix: 4
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 52273,
          st: 0,
          ct: 1,
          bm: 0
        }
      ]
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      td: 1,
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [100, 100, 0], ix: 2, l: 2 },
        a: { a: 0, k: [540, 540, 0], ix: 1, l: 2 },
        s: { a: 0, k: [18.519, 18.519, 100], ix: 6, l: 2 }
      },
      ao: 0,
      w: 1080,
      h: 1080,
      ip: 0,
      op: 52234,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      tt: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [101.523, 98.163, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-415.75, -535.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [85.821, 85.821, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-436, -536],
                    [-422, -522.5],
                    [-395.5, -548.5]
                  ],
                  c: false
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 6, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0, ix: 1 },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.072], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 0,
                s: [0]
              },
              { t: 12, s: [100] }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          hd: false
        }
      ],
      ip: 0,
      op: 52234,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [100, 100, 0], ix: 2, l: 2 },
        a: { a: 0, k: [540, 540, 0], ix: 1, l: 2 },
        s: { a: 0, k: [18.519, 18.519, 100], ix: 6, l: 2 }
      },
      ao: 0,
      w: 1080,
      h: 1080,
      ip: 0,
      op: 52234,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
